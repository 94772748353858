import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { Component } from 'react';
import './FoodBoard.css';
import moment from 'moment';
import 'firebase/compat/auth';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import Utilities from '../../../js/Utilities';

const TRACKER_COLLECTION_NAME = 'GalleriaSnacks';

const firebaseConfig = {
  apiKey: 'AIzaSyAB10QoTXFcdUCaVSbZntf_d_cSj7OSjHM',
  authDomain: 'gottea-3daa4.firebaseapp.com',
  databaseURL: 'https://gottea-3daa4.firebaseio.com',
  projectId: 'gottea-3daa4',
  storageBucket: 'gottea-3daa4.appspot.com',
  messagingSenderId: '691417546594',
  appId: '1:691417546594:web:536ef6d0c379fb5947b63b',
  measurementId: 'G-ED1W31MB1J',
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);
// Use these for db & auth
const db = firebaseApp.firestore();

function FoodBox({buttonClick, data}){
  let isDineIn = data.orderType.toLowerCase() === "dinein";
  let orderType = data.orderType;
  let orderTime = moment(data.createdAt.toDate()).format("hh:mm A");
  // let orderTime = moment(new Date()).format("hh:mm A");
  // let orderTime = moment(data.createdAt).format("hh:mm A");
  let orderNo = data.orderNumber;
  let status = data.foodStatus;
  let buttonTitle = 'Start Preparing';
  let snackType = '';
  if(data.name.toLowerCase().includes("croffle")){
    snackType = 'Croffle';
  }
  if(data.name.toLowerCase().includes("wrap")){
    snackType = 'Wrap';
  }
  if(data.name.toLowerCase().includes("toast")){
    snackType = 'KToast';
  }
  if(data.name.toLowerCase().includes("financier")){
    snackType = 'Financier';
  }
  if(data.name.toLowerCase().includes("tiramisu") || data.name.toLowerCase().includes("pudding")){
    snackType = 'Pudding';
  }
  if(data.name.toLowerCase().includes("grilled")){
    snackType = 'Grilled Sandwich';
  }
  if(data.name.toLowerCase().includes("creamy mushroom")){
    snackType = 'Grilled Sandwich';
  }
  let imgLink = `https://firebasestorage.googleapis.com/v0/b/gottea-3daa4.appspot.com/o/product-thumbnails%2F${snackType}-Thumbnail.jpg?alt=media&token=53da054f-02d8-41e2-aefc-d7dd259aabd2`;
  if(status === 'Preparing'){
    buttonTitle = 'Mark Ready';
  } else if(status === 'Ready'){
    buttonTitle = 'Complete';
  }
  if(isDineIn){
    orderType = 'Dine In';
  } else {
    orderType = 'Takeaway';
    if(data.orderType.toLowerCase() === "delivery"){
      orderNo = orderNo.substring(orderNo.length - 4,  orderNo.length);
      orderType = 'Delivery';
    }
  }
  let options = [];
  if(data.options){
    for(let key in data.options){
      options.push({ name : key,  val : data.options[key]});
    }
  }
  return <div
      className="croffleBox"
    >
  {/* <span className='orderNo'>#{orderNo}</span> */}
  <div className='croffleInfo'>
  <div className="packingLabel" style={
    {backgroundColor:(orderType.toLowerCase() === 'takeaway' || orderType.toLowerCase() === 'delivery') ? '#47908A' : '#47908A'}}>
      <span className='orderNo'>#{orderNo}</span> 
      <span className='orderType' style={
    {backgroundColor:(orderType.toLowerCase() === 'takeaway' || orderType.toLowerCase() === 'delivery') ? '#e74c3c' : '#27ae60'}}>{orderType}</span> 
      {/* <span className='snackLabel'>{snackType}</span>  */}
    <span className='orderTime'>{orderTime}</span>
  </div>
  <div className={'croffleLabel'} style={{
        backgroundColor: data.isNonVeg ? '#e74c3c':'#27ae60'
      }}>{data.isNonVeg ? 'NON-VEG' : 'VEG'}</div>
  <div className={'croffleLabel'} style={{
        backgroundColor: '#2C3A47',
          borderColor: '#2C3A47'
      }}>
      {snackType ? <span className='snackLabel'>{snackType}</span> : ''}
      {data.name}</div>
  </div>
  {
    Utilities.isNullOrEmpty(options) ? '' : <div className='snackOptionsContainer'>{options.map((opt)=>{ return <div>{opt.name} : {opt.val}</div>})}</div>
  }
  <div className='actionBtn' onClick={()=>{buttonClick(data.id, buttonTitle);}}>{buttonTitle}</div>
</div>;
}

function FoodBoxNew({buttonClick, data}){
  let isDineIn = data.orderType.toLowerCase() === "dinein";
  let orderType = data.orderType;
  let orderTime = moment(data.createdAt.toDate()).format("hh:mm A");
  // let orderTime = moment(new Date()).format("hh:mm A");
  let orderNo = data.orderNumber;
  let status = data.foodStatus;
  let buttonTitle = 'Start Preparing';
  if(status === 'Preparing'){
    buttonTitle = 'Mark Ready';
  } else if(status === 'Ready'){
    buttonTitle = 'Complete';
  }
  if(isDineIn){
    orderType = 'Dine In';
  } else {
    orderType = 'Takeaway';
    if(data.orderType.toLowerCase() === "delivery"){
      orderNo = orderNo.substring(orderNo.length - 4,  orderNo.length);
      orderType = 'Delivery';
    }
  }

  return <div
      className="croffleBox"
    >
  <div className='croffleInfo'>
  <div className="packingLabel" style={
    {backgroundColor:(orderType.toLowerCase() === 'takeaway' || orderType.toLowerCase() === 'delivery') ? '#47908A' : '#47908A'}}>
      <span className='orderNo'>#{orderNo}</span> 
      <span className='orderType' style={
    {backgroundColor:(orderType.toLowerCase() === 'takeaway' || orderType.toLowerCase() === 'delivery') ? '#e74c3c' : '#27ae60'}}>{orderType}</span> 
      <span className='orderTime'>{orderTime}</span>
  </div>
  <div className={'croffleLabel'} style={{
        backgroundColor: data.isNonVeg ? '#e74c3c':'#27ae60'
      }}>{data.isNonVeg ? 'NON-VEG' : 'VEG'}</div>
  <div className={'croffleLabel'} style={{
        backgroundColor:'#C1A97E',
          borderColor: '#C1A97E'
      }}>{data.name}</div>
  </div>
  <div className='actionBtn' onClick={()=>{buttonClick(data.id, buttonTitle);}}>{buttonTitle}</div>
</div>;
}

export default class App extends Component {
  constructor() {
    super({});
    this.state = {
      newFood: [],
      preparingFood: [],
      readyFood:[]
    };
    this.audioThingy = React.createRef();
  }

  loadFood() {
    db.collection(TRACKER_COLLECTION_NAME)
      .where('foodStatus', '==', 'Order Placed')
      .orderBy('createdAt', 'asc')
      .onSnapshot((querySnapshot) => {
        const newFood = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          newFood.push(data);
        });
        this.setState({
          newFood
        },()=>{
          if(this.audioThingy && this.audioThingy.play){
            this.audioThingy.play();
          }
        });
      });
    db.collection(TRACKER_COLLECTION_NAME)
      .where('foodStatus', '==', 'Preparing')
      .orderBy('createdAt', 'asc')
      .onSnapshot((querySnapshot) => {
        const preparingFood = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          preparingFood.push(data);
        });
        this.setState({
          preparingFood,
        });
      });
      db.collection(TRACKER_COLLECTION_NAME)
      .where('foodStatus', '==', 'Ready')
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        const readyFood = [];

        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          readyFood.push(data);
        });
        this.setState({
          readyFood
        });
      });
  }

  componentDidMount(){
    firebase.auth().onAuthStateChanged(user => {
        let loggedIn = !!user;
        if(loggedIn){
            this.loadFood();
        } else {
            window.location.href = '/login';
        }
    });
}

  handleButtonClick = async (foodId, action)=>{
    if(action.toLowerCase() === "start preparing"){
      let docRef = db.collection(TRACKER_COLLECTION_NAME).doc(foodId);
      await docRef.update({
        foodStatus : "Preparing"
      });
    }
    if(action.toLowerCase() === "mark ready"){
      let docRef = db.collection(TRACKER_COLLECTION_NAME).doc(foodId);
      await docRef.update({
        foodStatus : "Ready"
      });
    }
    if(action.toLowerCase() === "complete"){
      let docRef = db.collection(TRACKER_COLLECTION_NAME).doc(foodId);
      await docRef.update({
        foodStatus : "Closed"
      });
    }
  }

  render() {
    let { newFood, preparingFood, readyFood } = this.state;
     return (
       <div style={{margin:'auto'}}>
        <ReportsHeader/>
         {newFood.length > 0 ? <audio loop ref={(input) => {this.audioThingy = input}} src={'https://firebasestorage.googleapis.com/v0/b/gottea-3daa4.appspot.com/o/notification.wav?alt=media&token=f2616c6d-5681-428f-8ef8-0e3940731698'} style={{ display: 'none' }} /> : ''}
      <div className="foodContainer">
        <div className="foodColumnThin">
          <div><h2 className="columnLabel">New</h2></div>
          <div className="foodList">
        {newFood.map((item,index) => {
          return <FoodBoxNew  key={`newCroffle${index}`} buttonClick={this.handleButtonClick} data={item}/>;
        })}
        </div>
        </div>
        <div className="crofflesColumn">
        <div><h2 className="columnLabel">Preparing</h2></div>
        <div className="foodList">
        {preparingFood.map((item,index) => {
          return <FoodBox  key={`preparingCroffle${index}`} buttonClick={this.handleButtonClick} data={item}/>;
        })}
        </div>
        </div>
        <div className="foodColumnThin">
        <div><h2 className="columnLabel">Ready</h2></div>
        <div className="foodList">
        {readyFood.map((item,index) => {
          return <FoodBoxNew key={`readyCroffle${index}`} buttonClick={this.handleButtonClick} data={item}/>;
        })}
        </div>
        </div>
      </div>
    </div>);
  }
}